<template>
    <li class="list__item item item_view_head"
        :class="{'item_active': showActive == true}"
        @click.stop="showActive = !showActive"
    >
        <h4 class="list__title"> {{groupName}} ({{groupItems}}) </h4>
<!--        <NotificationsList_group_status :group-id="groupId" />-->
<!--        <NotificationsList_group_menu v-if="true" :group-id="groupId" />-->
    </li>
</template>
<script>
    import {
        // mapActions,
        // mapMutations,
        mapGetters,
    } from "vuex";

    export default {
        name: 'NotificationsList_group',
        components: {},
        props: [
            'groupId',
            // 'group',
        ],
        data() {
            return {
                showActive: false,
            }
        },
        computed: {
            ...mapGetters([
                "notificationsGroups",
                "notificationsGroupsByIds",
                "notificationsGroupsFiltered",
            ]),
            group(){
                return this.notificationsGroupsFiltered.find(g => {
                    return g.id === this.groupId
                })
                // return this.notificationsGroupsByIds[this.groupId]
            },
            groupName(){
                return this.group.name
            },
            groupItems(){
                return this.group.notifications.length
            },
        },
        methods: {
        },
        updated() {
            //console.log(`group ${this.groupId} updated`);
        }
    }
</script>