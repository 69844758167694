<template>
    <div class="list__content">
        <table class="list__table table">
            <tr>
                <th>{{ $t('Notification.Creator') }}:</th>
                <td></td>
            </tr>
            <tr>
                <th>{{ $t('Notification.Type') }}:</th>
                <td></td>
            </tr>
            <tr>
                <th>{{ $t('Notification.Alert') }}:</th>
                <td></td>
            </tr>
            <tr>
                <th>{{ $t('Notification.Units') }}: </th>
                <td>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'NotificationsList_item_content',
        props: [
            'notificationId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "notificationsByIds",
            ]),
            notification(){
                return this.notificationsByIds[this.notificationId]
            },
        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`NotificationsList_item_status ${this.notificationId} updated`);
        },
    }
</script>