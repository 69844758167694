<template>
    <!--- Navigation --->
    <nav class="section__navigation plr__12 pt__12">
        <div class="horizontal-line mb__12">
            <SegmentControl
                :segment-control-class="'mr__8'"
                :segment-control-item-class="'item_active'"
                :items="{'': 'Notifications'}"
            />

            <button class="button button_view_icon button_theme_base mtb__-4" @click="displaySectionInfo('NotificationEdit')">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add" /></span> </span>
            </button>

            <ButtonIcon_dual_status
                :button-class="'mtb__-4'"
                :icon="showListFilter ? 'common__filter_on' : 'common__filter'"
                :icons="['common__filter_on', 'common__filter']"
                :value="showListFilter"
                @click="switchListFilter"
            />
        </div>
        <div class="filter-search mt__12 "
             :class="{'status_hidden': !showListFilter}"
        >
            <button class="filter-search__filter button button_view_icon button_theme_primary">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__slider" /></span> </span>
            </button>
            <ul class="filter-search__choice">
                <!--                    <li class="filter-search__choice-item">Lost connection</li>-->
                <!--                    <li class="filter-search__choice-item">Control of the entrance to geosites</li>-->
                <!--                    <li class="filter-search__choice-item">Sensors control</li>-->
            </ul>
            <input class="filter-search__input" type="text" :placeholder="$t('text.Search')">
            <button class="filter-search__close button button_view_icon button_theme_primary">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
            </button>
            <div class="filter-search__dropdown-menu dropdown-menu">
                <div class="dropdown-menu__wrap">
                    <ul class="dropdown-menu__list">
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__approaching_geopoint" /></span> </span>
                            <span class="dropdown-menu__item-name">Approaching geopoint</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__approaching_object" /></span> </span>
                            <span class="dropdown-menu__item-name">Approaching object</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__geozone_exit_control" /></span> </span>
                            <span class="dropdown-menu__item-name">Control exit from geosite</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__geozone_entrance_control" /></span> </span>
                            <span class="dropdown-menu__item-name">Control of the entrance to geosites</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__distance_geopoint" /></span> </span>
                            <span class="dropdown-menu__item-name">Distance geopoint</span>
                        </li>

                        <li class="dropdown-menu__separator"></li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__parking" /></span> </span>
                            <span class="dropdown-menu__item-name">Condition control parking</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__movement" /></span> </span>
                            <span class="dropdown-menu__item-name">Condition control movement</span>
                        </li>

                        <li class="dropdown-menu__separator"></li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__fuel_control" /></span> </span>
                            <span class="dropdown-menu__item-name">Fuel control</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon icon_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__drain" /></span> </span>
                            <span class="dropdown-menu__item-name">Drain control</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__refill" /></span> </span>
                            <span class="dropdown-menu__item-name">Refill control</span>
                        </li>

                        <li class="dropdown-menu__separator"></li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon icon_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__lost-connection" /></span> </span>
                            <span class="dropdown-menu__item-name">Lost connection</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon icon_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__overspeeding_gis" /></span> </span>
                            <span class="dropdown-menu__item-name">Overspeeding by GIS</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon icon_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__towing" /></span> </span>
                            <span class="dropdown-menu__item-name">Towing</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__maintenance" /></span> </span>
                            <span class="dropdown-menu__item-name">Maintenance</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__parameter_control" /></span> </span>
                            <span class="dropdown-menu__item-name">Parameters control</span>
                        </li>
                        <li class="dropdown-menu__item item item_has-checkbox">
                            <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                            <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__sensor_control" /></span> </span>
                            <span class="dropdown-menu__item-name">Sensors control</span>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "NotificationsList_navigation",
        components: {
        },
        data() {
            return {
                showListFilter: false,
            }
        },
        computed: {
            ...mapGetters([
            ])
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
            switchListFilter(){
                this.showListFilter = !this.showListFilter
            }
        },
        updated() {
            //console.log('NotificationsList_navigation updated')
        },
        mounted() {
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>