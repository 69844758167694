<template>
    <div class="list__status">
        <span class="icon" v-show="!isActive"> <span class="icon__symbol"><SvgIcon class="icon" name="common__status_not_notify" /></span> </span>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'NotificationsList_item_status',
        props: [
            'notificationId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "notificationsByIds",
            ]),
            notification(){
                return this.notificationsByIds[this.notificationId]
            },
            isActive(){
                return this.notification.status
            },
        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`NotificationsList_item_status ${this.notificationId} updated`);
        },
    }
</script>