<template>
    <li class="list__item item_has-content"
        :class="{'item_active': showActive == true}"
        @click.stop="showActive = !showActive"
    >
        <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="notificationIcon"/></span> </span>
        <h4 class="list__title">{{notificationName}}</h4>
        <NotificationsList_item_status :notification-id="notificationId" />
        <NotificationsList_item_menu :notification-id="notificationId" />
        <NotificationsList_item_content :notification-id="notificationId" />
    </li>
</template>
<script>
    import NotificationsList_item_status from "./NotificationsList_item_status.vue";
    import NotificationsList_item_menu from "./NotificationsList_item_menu.vue";
    import NotificationsList_item_content from "./NotificationsList_item_content.vue";
    import {mapGetters} from "vuex";

    export default {
        name: 'NotificationsList_item',
        components: {
            NotificationsList_item_content,
            NotificationsList_item_menu,
            NotificationsList_item_status
        },
        props: [
            'notificationId',
            'groupId',
            'filteredNotification'
        ],
        data() {
            return {
                showActive: false,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "notificationsByIds",
                "notificationsGroupsByIds",
            ]),
            notificationName(){
                let notification = this.notificationsByIds[this.notificationId]
                return (notification && notification.name>'') ? notification.name : '-'
            },
            notificationIcon(){
                let notification = this.notificationsByIds[this.notificationId]
                if(notification.images && notification.images.length){
                    return 'notifications__' + notification.images[0]
                }
                return ''
            },
            // isShowUnit(){
            //     return !(!this.groupsShowUnitsByIds[this.groupId])
            // },
            // isFiltered(){
            //     return this.filteredUnits.includes(this.notificationId)
            // },
            isValid(){
                let notification = this.notificationsByIds[this.notificationId]
                return notification && notification.name > ''
            },
        },
        updated: function() {
            //console.log(`notification ${this.notificationId} updated`);
        },
        // mounted() {
        //     this.interval = setInterval(() => {
        //         this.now = Date.now()
        //     }, 1000)
        // },
        // beforeDestroy() {
        //     if(this.interval) {
        //         clearInterval(this.interval)
        //         this.interval = null
        //     }
        // },
    }
</script>
