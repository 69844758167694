<template>
    <div class="list__button-group">
        <button class="button button_view_icon button_theme_base mtb__-4" >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
        </button>
        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
        </button>
        <ButtonIcon_dual_status
            :button-class="'mtb__-4'"
            :icon="isActive ? 'common__notice' : 'common__notice_disabled'"
            :icons="['common__notice', 'common__notice_disabled']"
            :value="isActive"
        />
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'NotificationsList_item_menu',
        props: [
            'notificationId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "notificationsByIds",
            ]),
            notification(){
                return this.notificationsByIds[this.notificationId]
            },
            isActive(){
                return this.notification.status
            },
        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`NotificationsList_item_status ${this.notificationId} updated`);
        },
    }
</script>