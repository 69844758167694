<template>
    <section class="section__body">

        <!--- Navigation --->
        <NotificationsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <RecycleScroller
                    :items="notificationsGroupsFilteredWithSize"
                    :min-item-size="56"
                    :buffer="1000"
                    page-mode
            >
                <template #before>
                    <ul class="list mb__12">
                        <li class="list__item item item_view_head">
                            <h4 class="list__title">{{ $t('text.All notifications') }} ({{notificationsCount}})</h4>
                        </li>
                    </ul>
                </template>

                <template v-slot="{ item, index, active }">
<!--                    <DynamicScrollerItem-->
<!--                            :item="item"-->
<!--                            :active="active"-->
<!--                            :data-index="index"-->
<!--                            :data-active="active"-->
<!--                            :size-dependencies="[groupsShowNotifications,filteredNotifications,item.notifications,]"-->
<!--                            tag="ul"-->
<!--                            class="list mb__12"-->
<!--                            :class="{'list_status_collapsed': !groupsShowNotifications.includes(item.id)}"-->
<!--                    >-->
                    <ul class="list mb__12"
                        :key="'NotificationsList--'+item.id"
                        :active="active"
                        :data-index="index"
                        :data-active="active"
                    >
                        <NotificationsList_group
                                :key="'NotificationsList-group-'+item.id"
                                :group-id="item.id"
                        />

                        <NotificationsList_item
                                v-for="notificationId in item.notifications"
                                :key="'NotificationsList-group-'+item.id+'-notification-'+notificationId"
                                :notification-id="notificationId"
                                :group-id="item.id"
                                :filtered-notification="filteredNotifications.includes(notificationId)"
                        />

                    </ul>
<!--                    </DynamicScrollerItem>-->
                </template>

                <template #after>
                    <!--  end -->
                </template>
            </RecycleScroller>

        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import NotificationsList_navigation from "./NotificationsList_navigation.vue";
    import NotificationsList_item from "./NotificationsList_item.vue";
    import NotificationsList_group from "./NotificationsList_group.vue";

    export default {
        name: "NotificationsList",
        components: {
            NotificationsList_group,
            NotificationsList_item,
            NotificationsList_navigation,
        },
        data() {
            return {
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "notificationsCount",
                "notificationsGroups",
                "notificationsByIds",
                "filteredNotifications",
                "notificationsGroupsFiltered",
            ]),
            notificationsGroupsFilteredWithSize(){
                // console.log('notificationsGroupsFilteredWithSize', this.notificationsGroups, this.filteredNotifications)
                let collator = new Intl.Collator();
                let groups = this.notificationsGroupsFiltered
                groups.sort(function (a, b) {
                    let cmp = collator.compare(a.name, b.name)
                    if (cmp) {
                        return cmp;
                    }
                    return a.id - b.id;
                })

                // console.log('groups.length', groups.length)
                return groups.reduce((out, g) => {
                    let size = 41*g.notifications.length
                    out.push({
                        ...g,
                        size: 44+12+size
                    })
                    return out
                }, [])
            },
/*
            notificationsGroupsWithSize(){
                // console.log('notificationsGroupsWithSize', this.notificationsGroups, this.filteredNotifications)
                return this.notificationsGroups.reduce((out, g) => {
                    let isShowNotifications = this.groupsShowNotifications.includes(g.id)
                    let size = g.notifications.length*52*isShowNotifications
                    // let size = 0
                    // if (isShowNotifications) {
                    //     let notifications = g.notifications.filter(u => {
                    //         return this.filteredNotifications.includes(u.id)
                    //     })
                    //     size = 52*notifications.length
                    // }
                    out.push({
                        id: g.id,
                        notifications: g.notifications,
                        size: 44+12+size
                    })
                    return out
                }, [])
            }
 */
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "fetchNotifications",
                "fetchNotificationsGroups",
            ]),
        },
        updated() {
            //console.log('NotificationsList updated')
        },
        mounted() {
            if(!this.notificationsGroups.length) {
                this.fetchNotificationsGroups();
            }
            this.fetchNotifications()
            this.interval = setInterval(() => {
                this.fetchNotifications()
            }, 30*1000)
        },
        beforeDestroy() {
            if(this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },

    }
</script>

